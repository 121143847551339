import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Gap from "../../../components/atoms/Gap";
import { getBlog } from "../../../redux/action/blog";

const FeedDetail = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBlog(id));
  }, [dispatch]);

  const { blog } = useSelector((state) => state.blog);

  return (
    <>
      <div className="white">
        <div className="container">
          <Gap height={10} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <a href="/feed" style={styles.breadcrumb}>
              Home
            </a>
            <i className="material-icons">navigate_next</i>
            <div style={styles.breadcrumb}>{blog.BlogCategory?.name}</div>
            <i className="material-icons">navigate_next</i>
            <div style={styles.breadcrumb}>{blog.BlogSubCategory?.name}</div>
          </div>
          <Gap height={5} />
          <div style={styles.title}>{blog.title}</div>
          <div className="teal-text" style={styles.author}>
            {blog.Author?.name}
          </div>
          <Gap height={5} />
          <div className="grey-text" style={styles.createdAt}>
            {blog.createdAt}
          </div>
          <Gap height={10} />
          <div className="center">
            <img
              src={`//${blog.image}`}
              className="responsive-img"
              style={styles.image}
            />
          </div>
          <div>
            <pre style={styles.content}>{blog.content}</pre>
          </div>
          <Gap height={20} />
        </div>
      </div>
      <Gap height={70} />
    </>
  );
};

export default FeedDetail;

const styles = {
  breadcrumb: { fontSize: 16 },
  title: { fontSize: 22 },
  author: { fontSize: 16 },
  createdAt: { fontSize: 14 },
  image: { maxHeight: "400px", height: "70%", width: "auto" },
  content: {
    fontSize: 16,
    whiteSpace: "pre-line",
    wordWrap: "break-word",
    textAlign: "justify",
  },
};

import axios from "axios";
import Api from "../../../api/Api";

const getToken = () => {
  return localStorage.getItem("kejetkeun-token");
};

const storeRegister = (form) => {
  return new Promise((resolve, reject) => {
    Api.post("/users", form)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const storeLogin = (form) => {
  return new Promise((resolve, reject) => {
    Api.post("/users/login", form)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const authentication = () => {
  return new Promise((resolve, reject) => {
    Api.get("/users", {
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const stravaIntegration = async (code) => {
  const response = await axios.post(
    `https://www.strava.com/oauth/token?client_id=77647&client_secret=e621c7568bfc1eec5b39cfbe54c400615087015e&code=${code}&grant_type=authorization_code`
  );

  return new Promise((resolve, reject) => {
    Api.post("/users/strava-integration", response, {
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export {
  authentication,
  getToken,
  storeLogin,
  storeRegister,
  stravaIntegration,
};

const stateBlogCategories = {
  blog_categories: [],
};

const blogCategories = (state = stateBlogCategories, action) => {
  if (action.type === "GET_BLOG_CATEGORIES") {
    return {
      ...state,
      blog_categories: action.payload,
    };
  }

  return state;
};

export { blogCategories };

const stateProfile = {
  profile: {},
};

const stateUsers = {
  users: [],
};

const profile = (state = stateProfile, action) => {
  if (action.type === "GET_PROFILE") {
    return {
      ...state,
      profile: action.payload,
    };
  }

  return state;
};

const users = (state = stateUsers, action) => {
  if (action.type === "GET_USERS") {
    return {
      ...state,
      users: action.payload,
    };
  }

  return state;
};

export { profile, users };

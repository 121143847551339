import Api from "../../../api/Api";

const getBlogSubCategories = (blogCategoryId) => (dispatch) => {
  Api.get(`/blog-sub-categories/blog-categories/${blogCategoryId}`)
    .then((res) => {
      dispatch({
        type: "GET_BLOG_SUB_CATEGORIES",
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log(err.response.data);
    });
};

export { getBlogSubCategories };

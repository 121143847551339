import M from "materialize-css";
import { useEffect } from "react";

const Nav = () => {
  useEffect(() => {
    var elems = document.querySelectorAll(".sidenav");
    M.Sidenav.init(elems, {});
  }, []);

  return (
    <>
      <div className="navbar-fixed hide-on-small-only">
        <nav className="teal">
          <div className="container">
            <div className="nav-wrapper">
              <a
                href="#"
                data-target="slide-out"
                className="sidenav-trigger show-on-large"
              >
                <i className="material-icons">menu</i>
              </a>
            </div>
          </div>
        </nav>
      </div>
      <ul id="slide-out" className="sidenav">
        <li>
          <a href="/dashboard">Beranda</a>
        </li>
        <li>
          <a href="/feed">Feed</a>
        </li>
        <li>
          <a href="/leaderboard">Klasemen</a>
        </li>
        <li>
          <a href="/my">Saya</a>
        </li>
      </ul>
    </>
  );
};

export default Nav;

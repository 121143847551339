import React from "react";
import { useSearchParams } from "react-router-dom";
import { stravaIntegration } from "../../redux/action/auth";

const ExchangeToken = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const code = searchParams.get("code");

  if (code) {
    stravaIntegration(code)
      .then((res) => {
        alert(res.message);
        window.location.href = "/my";
      })
      .catch((err) => {
        alert(err.message);
        window.location.href = "/my";
      });
  }

  return <div>Integration...</div>;
};

export default ExchangeToken;

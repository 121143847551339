import Api from "../../../api/Api";

const storeBlog = (form) => {
  return new Promise((resolve, reject) => {
    Api.post("/blogs", form)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getBlogs =
  (blogCategoryId = "", blogSubCategoryId = "") =>
  (dispatch) => {
    if (!blogCategoryId && !blogSubCategoryId) {
      Api.get("/blogs")
        .then((res) => {
          dispatch({
            type: "GET_BLOGS",
            payload: res.data.data,
          });
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }

    if (blogCategoryId && !blogSubCategoryId) {
      Api.get(`/blogs?blogCategoryId=${blogCategoryId}`)
        .then((res) => {
          dispatch({
            type: "GET_BLOGS",
            payload: res.data.data,
          });
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }

    if (blogCategoryId && blogSubCategoryId) {
      Api.get(
        `/blogs?blogCategoryId=${blogCategoryId}&blogSubCategoryId=${blogSubCategoryId}`
      )
        .then((res) => {
          dispatch({
            type: "GET_BLOGS",
            payload: res.data.data,
          });
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
  };

const getBlog = (id) => (dispatch) => {
  Api.get(`/blogs/${id}`)
    .then((res) => {
      dispatch({
        type: "GET_BLOG",
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log(err.response.data);
    });
};

const updateBlog = (id, form) => {
  return new Promise((resolve, reject) => {
    Api.put(`/blogs/${id}`, form)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const destroyBlog = (id) => {
  return new Promise((resolve, reject) => {
    Api.delete(`/blogs/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export { storeBlog, getBlogs, getBlog, updateBlog, destroyBlog };

import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import GlobalApp from "./layouts/GlobalApp";
import MainApp from "./layouts/MainApp";
import Dashboard from "./pages/Dashboard";
import ExchangeToken from "./pages/ExchangeToken";
import Feed from "./pages/Feed";
import Home from "./pages/Home";
import Leaderboard from "./pages/Leaderboard";
import Login from "./pages/Login";
import My from "./pages/My";
import Register from "./pages/Register";
import Sync from "./pages/Sync";
import store from "./redux/store";
import Blog from "./pages/Blog";
import BlogCreate from "./pages/Blog/Create";
import BlogUpdate from "./pages/Blog/Update";
import FeedDetail from "./pages/Feed/Detail";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<GlobalApp />}>
            <Route index element={<Home />} />
          </Route>
          <Route path="" element={<MainApp />}>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/my" element={<My />} />
            <Route path="/exchange_token" element={<ExchangeToken />} />
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/feed">
              <Route index element={<Feed />} />
              <Route path=":id" element={<FeedDetail />} />
            </Route>
            <Route path="/sync" element={<Sync />} />
            <Route path="/blog">
              <Route index element={<Blog />} />
              <Route path="create" element={<BlogCreate />} />
              <Route path=":id/update" element={<BlogUpdate />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Gap from "../../components/atoms/Gap";
import { getLeaderboard } from "../../redux/action/segmentEffort";
import M from "materialize-css";

const Leaderboard = () => {
  useEffect(() => {
    var elems = document.querySelectorAll("select");
    M.FormSelect.init(elems, {});
  });

  const [segmentId = 35951755, setSegmentId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const btnSet = (date) => {
    setIsLoading(true);
    getLeaderboard(segmentId, date)
      .then((res) => {
        setIsLoading(false);
        dispatch({
          type: "GET_LEADERBOARD",
          payload: res.data,
        });
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  const { leaderboard } = useSelector((state) => state.leaderboard);

  return (
    <>
      <div className="white">
        <div className="container">
          <Gap height={10} />
          <div className="center" style={styles.title}>
            <b>Klasemen Kemis Kejetkeun</b>
          </div>
          <div class="input-field" style={styles.input}>
            <select
              onChange={(e) => {
                setSegmentId(e.target.value);
              }}
            >
              <option value="" disabled>
                Pilih Segment
              </option>
              <option value={35951755} selected>
                KK1 | Tugu Cimaung - Puntang
              </option>
              <option value={32782397}>KK2 | Sadu - Polsek Pasir Jambu</option>
            </select>
            <label>Segment</label>
          </div>
          <div style={styles.spaceBetween}>
            {(() => {
              if (segmentId == 35951755) {
                return (
                  <>
                    <div>
                      <div>Jarak</div>
                      <div>7,34 km</div>
                    </div>
                    <div>
                      <div>Elevation</div>
                      <div>407 m</div>
                    </div>
                    <div>
                      <div>Grade</div>
                      <div>5,5 %</div>
                    </div>
                    <a
                      href={`https://www.strava.com/segments/${segmentId}`}
                      className="btn deep-orange"
                    >
                      Strava
                    </a>
                  </>
                );
              }
              if (segmentId == 32782397) {
                return (
                  <>
                    <div>
                      <div>Jarak</div>
                      <div>8.82 km</div>
                    </div>
                    <div>
                      <div>Elevation</div>
                      <div>368 m</div>
                    </div>
                    <div>
                      <div>Grade</div>
                      <div>3.4 %</div>
                    </div>
                    <a
                      href={`https://www.strava.com/segments/${segmentId}`}
                      className="btn orange darken-4"
                    >
                      Strava
                    </a>
                  </>
                );
              }
            })()}
          </div>
          {segmentId ? (
            <div class="input-field" style={styles.input}>
              <input
                type="date"
                onChange={(e) => {
                  btnSet(e.target.value);
                }}
              />
              <label>Tanggal</label>
            </div>
          ) : null}
        </div>
      </div>
      <Gap height={10} />
      {isLoading === true ? (
        <span>Loading...</span>
      ) : (
        leaderboard.map((value, index) => (
          <>
            <div className="white">
              <Gap height={5} />
              <a
                href={`https://www.strava.com/activities/${value.activityId}`}
                className="container valign-wrapper"
                style={{ fontSize: 16, color: "#404040" }}
              >
                <div>{index + 1}.</div>
                <img
                  src={`${value.User.profile}`}
                  style={{
                    width: 60,
                    height: 60,
                    borderRadius: "100%",
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                />
                <div>
                  <div>
                    {value.User.firstname} {value.User.lastname}
                  </div>
                  <div style={{ fontWeight: "bold" }}>{value.elapsedTime}</div>
                  <div style={{ fontSize: 15 }}>{value.avgSpeedET}</div>
                </div>
              </a>
              <Gap height={5} />
            </div>
            <Gap height={1} />
          </>
        ))
      )}
      <Gap height={70} />
    </>
  );
};

export default Leaderboard;

const styles = {
  title: { fontSize: 16 },
  input: { marginBottom: -3 },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
};

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Gap from "../../components/atoms/Gap";
import { authentication } from "../../redux/action/auth";

const My = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("kejetkeun-token")) {
      authentication()
        .then((res) => {
          dispatch({
            type: "GET_PROFILE",
            payload: res.data,
          });
        })
        .catch((err) => {
          window.location.href = "/login";
        });
    } else {
      window.location.href = "/login";
    }
  }, []);

  const { profile } = useSelector((state) => state.profile);

  const btnIntegration = () => {
    window.location.href =
      "https://www.strava.com/oauth/authorize?client_id=77647&response_type=code&redirect_uri=https://kemiskejetkeun.com/exchange_token&approval_prompt=force&scope=activity:read";
  };

  const btnLogout = () => {
    localStorage.removeItem("kejetkeun-token");
    window.location.href = "/login";
  };

  return (
    <div className="white">
      <div className="container">
        <Gap height={10} />
        <div className="center">
          <img src={profile.profile} style={styles.image} />
        </div>
        <table style={styles.table}>
          <tr>
            <td>Strava (status)</td>
            <td>{profile.athleteId ? "Terhubung" : "Belum terhubung"}</td>
          </tr>
          <tr>
            <td>Username</td>
            <td>{profile.username}</td>
          </tr>
          <tr>
            <td>Nama</td>
            <td>
              {profile.firstname} {profile.lastname}
            </td>
          </tr>
          <tr>
            <td>Jenis Kelamin</td>
            <td>
              {profile.sex
                ? profile.sex === "M"
                  ? "Laki - Laki"
                  : "Perempuan"
                : null}
            </td>
          </tr>
          <tr>
            <td>Tanggal Lahir</td>
            <td>{profile.dateOfBirth}</td>
          </tr>
          <tr>
            <td>No. Telp</td>
            <td>{profile.phone}</td>
          </tr>
        </table>
        <Gap height={20} />
        <div style={styles.containerBtn}>
          {!profile.athleteId ? (
            <button
              className="btn red darken-2"
              onClick={() => {
                btnIntegration();
              }}
            >
              Hubungkan Strava
            </button>
          ) : (
            <a
              href={`https://www.strava.com/athletes/${profile.athleteId}`}
              className="btn deep-orange"
            >
              Lihat Strava
            </a>
          )}
          <button
            className="btn red darken-2"
            onClick={() => {
              btnLogout();
            }}
          >
            Sign Out
          </button>
        </div>
      </div>
      <Gap height={50} />
    </div>
  );
};

export default My;

const styles = {
  image: { width: 70, borderRadius: "100%" },
  table: { fontSize: 16 },
  containerBtn: { display: "flex", justifyContent: "space-between" },
};

import M from "materialize-css";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Gap from "../../components/atoms/Gap";
import { sync } from "../../redux/action/segmentEffort";
import { getUsers } from "../../redux/action/user";

const Sync = () => {
  useEffect(() => {
    var elems = document.querySelectorAll("select");
    M.FormSelect.init(elems, {});
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const { users } = useSelector((state) => state.users);

  const [segmentId, setSegmentId] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const checkboxHandler = (e) => {
    let isSelected = e.target.checked;
    let value = parseInt(e.target.value);

    if (isSelected) {
      setSelectedUsers([...selectedUsers, value]);
    } else {
      setSelectedUsers((prevData) => {
        return prevData.filter((id) => {
          return id !== value;
        });
      });
    }
  };

  const checkAllHandler = () => {
    if (users.length === selectedUsers.length) {
      setSelectedUsers([]);
    } else {
      const postIds = users.map((value) => {
        return value.id;
      });

      setSelectedUsers(postIds);
    }
  };

  const btnSync = () => {
    setIsLoading(true);
    if (parseInt(segmentId) === 35951755) {
      const code = "kk1";

      sync(code, segmentId, selectedUsers)
        .then((res) => {
          res.data.map((value) => {
            if (value.segmentEffort) {
              alert(
                `✔ | ${value.firstname} ${value.lastname} | ${moment(
                  value.segmentEffort.start_date_local
                ).format("DD-MM-YYYY")}`
              );
            } else {
              alert(`✘ | ${value.firstname} ${value.lastname}`);
            }
          });
          setIsLoading(false);
        })
        .catch((err) => alert(err.message));
    }

    if (parseInt(segmentId) === 32782397) {
      const code = "kk2";

      sync(code, segmentId, selectedUsers)
        .then((res) => {
          res.data.map((value) => {
            if (value.segmentEffort) {
              alert(
                `✔ | ${value.firstname} ${value.lastname} | ${
                  value.firstname
                } ${value.lastname} | ${moment(
                  value.segmentEffort.start_date_local
                ).format("DD-MM-YYYY")}`
              );
            } else {
              alert(`✘ | ${value.firstname} ${value.lastname}`);
            }
          });
          setIsLoading(false);
        })
        .catch((err) => alert(err.message));
    }
  };

  if (isLoading === true) {
    return <span>Loading...</span>;
  }

  return (
    <div className="white">
      <Gap height={10} />
      <div className="container">
        <select
          onChange={(e) => {
            setSegmentId(e.target.value);
          }}
        >
          <option value="" selected disabled>
            Pilih Segment
          </option>
          <option value={35951755}>Tugu Cimaung - Puntang</option>
          <option value={32782397}>Sadu - Polsek Pasir Jambu</option>
        </select>
        <div style={styles.spaceBetween}>
          <button className="btn" onClick={() => btnSync()}>
            Sync
          </button>
          <button className="btn" onClick={checkAllHandler}>
            {users.length === selectedUsers.length
              ? "Uncheck all"
              : "Check all"}
          </button>
        </div>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Atlit</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            {users.map((value, index) => (
              <tr>
                <td>{index + 1}</td>
                <td className="valign-wrapper">
                  <img
                    src={`${value.profile}`}
                    className="responsive-img"
                    style={styles.image}
                  />{" "}
                  {value.firstname} {value.lastname}
                </td>
                <td>
                  <p>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedUsers.includes(value.id)}
                        value={value.id}
                        onChange={checkboxHandler}
                      />
                      <span></span>
                    </label>
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Gap height={70} />
    </div>
  );
};

export default Sync;

const styles = {
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  image: { width: 50, borderRadius: "100%", marginRight: 10 },
};

import M from "materialize-css";
import { useEffect } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import Gap from "../../components/atoms/Gap";
import { authentication } from "../../redux/action/auth";
import { getDashboard } from "../../redux/action/segmentEffort";

const Dashboard = () => {
  useEffect(() => {
    var elems = document.querySelectorAll("select");
    M.FormSelect.init(elems, {});
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("kejetkeun-token")) {
      authentication()
        .then((res) => {
          dispatch({
            type: "GET_PROFILE",
            payload: res.data,
          });
          dispatch(getDashboard(35951755));
        })
        .catch((err) => {});
    }
  }, []);

  const { profile } = useSelector((state) => state.profile);

  const { dashboard } = useSelector((state) => state.dashboard);

  const options = {
    chart: {
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#FDD835"],
        shadeIntensity: 1,
        type: "horizontal",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    yaxis: {
      reversed: true,
    },
  };

  const series = [
    {
      name: "",
      data: dashboard.charts,
    },
  ];

  return (
    <div className="white">
      <div className="container">
        <Gap height={10} />
        {Object.keys(profile).length === 0 ? (
          <div className="card" style={styles.card}>
            <a href={"/login"} className="red-text">
              <Gap height={10} />
              <div className="center">
                <i className="material-icons" style={styles.icon}>
                  warning
                </i>
                <div style={styles.alertText}>
                  <b>Silahkan SIGN IN terlebih dahulu! Klik disini!</b>
                </div>
              </div>
              <Gap height={10} />
            </a>
          </div>
        ) : !profile.athleteId ? (
          <div className="card" style={styles.card}>
            <a href={"/my"} className="red-text">
              <Gap height={10} />
              <div className="center">
                <i className="material-icons" style={styles.icon}>
                  warning
                </i>
                <div style={styles.alertText}>
                  <b>
                    Silahkan HUBUNGKAN STRAVA anda terlebih dahulu! Klik disini!
                  </b>
                </div>
              </div>
              <Gap height={10} />
            </a>
          </div>
        ) : (
          <>
            <div style={styles.title}>
              Hello, {profile.firstname} {profile.lastname}
            </div>
            <div class="input-field" style={styles.input}>
              <select
                onChange={(e) => {
                  dispatch(getDashboard(e.target.value));
                }}
              >
                <option value={35951755} selected>
                  KK1 | Tugu Cimaung - Puntang
                </option>
                <option value={32782397}>
                  KK2 | Sadu - Polsek Pasir Jambu
                </option>
              </select>
              <label>Performa</label>
            </div>
            <div className="row valign-wrapper" style={styles.row}>
              <div className="col s4" style={styles.col}>
                <div className="card center" style={styles.second}>
                  <div style={styles.icon}>
                    <i class="fa-solid fa-medal grey-text"></i>
                  </div>
                  <div className="red-text" style={styles.y}>
                    <b>{dashboard.bestRecords?.[1].y}</b>
                  </div>
                  <div style={styles.x}>{dashboard.bestRecords?.[1].x}</div>
                </div>
              </div>
              <div className="col s4" style={styles.col}>
                <div className="card center" style={styles.first}>
                  <div style={styles.icon}>
                    <i class="fa-solid fa-medal yellow-text text-darken-3"></i>
                  </div>
                  <div className="red-text" style={styles.y}>
                    <b>{dashboard.bestRecords?.[0].y}</b>
                  </div>
                  <div style={styles.x}>{dashboard.bestRecords?.[0].x}</div>
                </div>
              </div>
              <div className="col s4" style={styles.col}>
                <div className="card center">
                  <div style={styles.icon}>
                    <i class="fa-solid fa-medal orange-text text-darken-3"></i>
                  </div>
                  <div className="red-text" style={styles.y}>
                    <b>{dashboard.bestRecords?.[2].y}</b>
                  </div>
                  <div style={styles.x}>{dashboard.bestRecords?.[2].x}</div>
                </div>
              </div>
            </div>
            <div className="mixed-chart">
              <Chart
                options={options}
                series={series}
                type="line"
                width="100%"
              />
            </div>
          </>
        )}
      </div>
      <Gap height={50} />
    </div>
  );
};

export default Dashboard;

const styles = {
  card: { padding: 10 },
  alertText: {
    fontSize: 16,
    textDecoration: "underline",
  },
  title: { fontSize: 18 },
  input: { marginBottom: -3 },
  row: { marginBottom: 0 },
  col: { padding: 2.5 },
  second: { padding: 5 },
  first: { padding: 10 },
  icon: { fontSize: 24 },
  y: { fontSize: 16 },
  x: { fontSize: 12 },
};

import Gap from "../../components/atoms/Gap";

const Home = () => {
  return (
    <>
      <div className="teal">
        <Gap height={5} />
        <div className="container">
          <div className="white-text">
            <div className="center">
              <h5>
                <b>Kemis Kejetkeun</b>
              </h5>
              <a href="/dashboard" className="btn orange">
                Masuk
              </a>
            </div>
          </div>
        </div>
        <Gap height={20} />
      </div>
      <div className="white">
        <div className="container">
          <div className="center">
            <img src="/home.png" className="responsive-img" />
          </div>
        </div>
      </div>
      <div className="teal">
        <Gap height={15} />
        <div className="container">
          <div className="white-text">
            <div className="center">
              <div style={{ fontSize: 16, fontWeight: "bold" }}>
                Connect with Us
              </div>
              <Gap height={5} />
              <a
                href="/https://www.instagram.com/kemiskejetkeun/"
                style={{ fontSize: 32, color: "white", marginRight: 15 }}
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                href="#"
                style={{ fontSize: 32, color: "white", marginRight: 15 }}
              >
                <i className="fa-brands fa-youtube"></i>
              </a>
              <a href="#" style={{ fontSize: 32, color: "white" }}>
                <i className="fa-brands fa-whatsapp"></i>
              </a>
              <Gap height={15} />
              <div style={{ fontSize: 16, fontWeight: "bold" }}>Technology</div>
              <Gap height={5} />
              <img src="/nodejs.png" height={45} style={{ marginRight: 10 }} />
              <img
                src="/expressjs.png"
                height={45}
                style={{ marginRight: 10 }}
              />
              <img src="/reactjs.png" height={45} />
            </div>
            <Gap height={5} />
            <hr />
            <Gap height={5} />
            <div className="center" style={{ fontSize: 16 }}>
              <span style={{ fontWeight: "bold" }}>© 2024 Kemis Kejetkeun</span>{" "}
              | Kemis Kejetkeun adalah merek milik komunitas olahraga balap
              sepeda di bandung
            </div>
          </div>
        </div>
        <Gap height={15} />
      </div>
      <div className="teal darken-3">
        <Gap height={5} />
        <div className="container">
          <div className="white-text">
            <div style={{ fontSize: 12 }}>
              <div className="center">
                Powered By{" "}
                <span style={{ fontWeight: "bold" }}>Fi-Production</span>
              </div>
            </div>
          </div>
        </div>
        <Gap height={5} />
      </div>
    </>
  );
};

export default Home;

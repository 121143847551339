import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Gap from "../../components/atoms/Gap";
import { authentication, storeLogin } from "../../redux/action/auth";

const Login = () => {
  useEffect(() => {
    if (localStorage.getItem("kejetkeun-token")) {
      authentication()
        .then((res) => {
          window.location.href = "/dashboard";
        })
        .catch((err) => {});
    }
  }, []);

  const [phone, setPhone] = useState();
  const [pin, setPin] = useState();
  const [validation, setValidation] = useState([]);

  const navigate = useNavigate();

  const formLogin = (e) => {
    e.preventDefault();

    const form = {
      phone,
      pin,
    };

    storeLogin(form)
      .then((res) => {
        localStorage.setItem("kejetkeun-token", res.data);
        alert(res.message);
        navigate("/dashboard");
      })
      .catch((err) => {
        if (err.message === "validation failed") {
          setValidation(err.data);
        } else {
          alert(err.message);
        }
      });
  };

  return (
    <div className="white">
      <div className="container">
        <Gap height={20} />
        <div className="center" style={styles.title}>
          Masuk
        </div>
        <form onSubmit={formLogin}>
          <div className="input-field">
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <label>No. Telp / WhatsApp</label>
            {validation?.map((value, index) =>
              value.field === "phone" ? (
                <span className="helper-text red-text" key={index}>
                  {value.message}
                </span>
              ) : null
            )}
          </div>
          <div className="input-field">
            <input
              type="password"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
            />
            <label>Pin (6 Digit)</label>
            {validation?.map((value, index) =>
              value.field === "pin" ? (
                <span className="helper-text red-text" key={index}>
                  {value.message}
                </span>
              ) : null
            )}
          </div>
          <button className="btn btn-large" style={styles.btn}>
            Masuk
          </button>
          <Gap height={10} />
          <div className="center">
            <a href="/register" style={styles.link}>
              Belum punya akun? daftar disini!
            </a>
          </div>
        </form>
      </div>
      <Gap height={50} />
    </div>
  );
};

export default Login;

const styles = {
  title: { fontSize: 22 },
  btn: { width: "100%" },
  link: { fontSize: 18 },
};

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Gap from "../../components/atoms/Gap";
import { destroyBlog, getBlogs } from "../../redux/action/blog";

const Blog = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBlogs());
  }, [dispatch]);

  const { blogs } = useSelector((state) => state.blogs);

  const btnDelete = (id) => {
    destroyBlog(id)
      .then((res) => {
        alert(res.message);
        dispatch(getBlogs());
      })
      .catch((err) => alert(err.message));
  };

  return (
    <>
      <div className="container">
        <Gap height={10} />
        <Link to="/blog/create" style={styles.link}>
          Tambah Data
        </Link>
        <Gap height={5} />
        {blogs.map((value, index) => (
          <div className="card" style={styles.card} key={index}>
            <div style={styles.container}>
              <img
                src={`//${value.image}`}
                className="responsive-img"
                style={styles.image}
              />
              <div className="grey-text text-darken-3" style={styles.title}>
                {value.title}
              </div>
            </div>
            <div style={styles.right}>
              <div style={styles.container}>
                <Link
                  to={`/blog/${value.id}/update`}
                  className="btn orange"
                  style={styles.btn}
                >
                  <i className="material-icons">edit</i>
                </Link>
                <button
                  className="btn red"
                  onClick={() => btnDelete(value.id)}
                  style={styles.btn}
                >
                  <i className="material-icons">delete</i>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Gap height={70} />
    </>
  );
};

export default Blog;

const styles = {
  link: { fontSize: 16 },
  card: {
    padding: 10,
    display: "flex",
    alignItems: "center",
  },
  container: { display: "flex", alignItems: "center" },
  image: { width: 70, height: 70, marginRight: 10 },
  title: { fontSize: 16 },
  right: { marginLeft: "auto" },
  btn: { marginLeft: 2 },
};

const stateAuthors = {
  authors: [],
};

const authors = (state = stateAuthors, action) => {
  if (action.type === "GET_AUTHORS") {
    return {
      ...state,
      authors: action.payload,
    };
  }

  return state;
};

export { authors };

import Api from "../../../api/Api";

const getUsers = () => (dispatch) => {
  Api.get("/users/list")
    .then((res) => {
      dispatch({
        type: "GET_USERS",
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log(err.response.data);
    });
};

export { getUsers };

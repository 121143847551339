const stateBlogs = {
  blogs: [],
};

const stateBlog = {
  blog: {},
};

const blogs = (state = stateBlogs, action) => {
  if (action.type === "GET_BLOGS") {
    return {
      ...state,
      blogs: action.payload,
    };
  }

  return state;
};

const blog = (state = stateBlog, action) => {
  if (action.type === "GET_BLOG") {
    return {
      ...state,
      blog: action.payload,
    };
  }

  return state;
};

export { blogs, blog };

import { combineReducers } from "redux";
import { profile, users } from "./auth";
import { leaderboard, dashboard } from "./segmentEffort";
import { authors } from "./author";
import { blogCategories } from "./blogCategory";
import { blogSubCategories } from "./blogSubCategory";
import { blogs, blog } from "./blog";

const reducer = combineReducers({
  profile,
  users,
  leaderboard,
  dashboard,
  authors,
  blogCategories,
  blogSubCategories,
  blogs,
  blog,
});

export default reducer;

import M from "materialize-css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Gap from "../../../components/atoms/Gap";
import { getAuthors } from "../../../redux/action/author";
import { storeBlog } from "../../../redux/action/blog";
import { getBlogCategories } from "../../../redux/action/blogCategory";
import { getBlogSubCategories } from "../../../redux/action/blogSubCategory";
import { convertToBase64 } from "../../../redux/action/convertToBase64";

const BlogCreate = () => {
  useEffect(() => {
    var elems = document.querySelectorAll("select");
    M.FormSelect.init(elems, {});
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAuthors());
    dispatch(getBlogCategories());
  }, [dispatch]);

  const { authors } = useSelector((state) => state.authors);
  const { blog_categories } = useSelector((state) => state.blogCategories);
  const { blog_sub_categories } = useSelector(
    (state) => state.blogSubCategories
  );

  const [previewImage, setPreviewImage] = useState();
  const [authorId, setAuthorId] = useState();
  const [blogCategoryId, setBlogCategoryId] = useState();
  const [blogSubCategoryId, setBlogSubCategoryId] = useState();
  const [image, setImage] = useState();
  const [title, setTitle] = useState();
  const [content, setContent] = useState();
  const [validation, setValidation] = useState([]);

  const handlePreviewImage = (e) => {
    const [file] = e.target.files;

    if (!file) return;

    setPreviewImage(URL.createObjectURL(file));
  };

  const handleFileUpload = async (e) => {
    const [file] = e.target.files;

    if (!file) return;

    const { size, type } = file;

    if (type !== "image/jpg" && type !== "image/jpeg" && type !== "image/png") {
      alert("Tipe yang diperbolehkan hanya jpg, jpeg, & png");
      setImage();
    } else if (size > 1000000) {
      alert("Ukuran gambar maksimal 1 mb");
      setImage();
    } else {
      setImage(await convertToBase64(file));
    }
  };

  const navigate = useNavigate();

  const formBlog = (e) => {
    e.preventDefault();

    const form = {
      authorId: parseInt(authorId),
      blogCategoryId: parseInt(blogCategoryId),
      blogSubCategoryId: parseInt(blogSubCategoryId),
      image,
      title,
      content,
    };

    storeBlog(form)
      .then((res) => {
        alert(res.message);
        navigate("/blog");
      })
      .catch((err) => {
        if (err.message === "validation failed") {
          setValidation(err.data);
        } else {
          alert(err.message);
        }
      });
  };

  return (
    <>
      <div className="container">
        <form className="card" onSubmit={formBlog} style={styles.card}>
          <img
            src={previewImage}
            className="responsive-img"
            style={styles.image}
          />
          <div className="file-field input-field">
            <div className="btn">
              <i className="material-icons">image</i>
              <input
                type="file"
                onChange={(e) => {
                  handlePreviewImage(e);
                  handleFileUpload(e);
                }}
              />
            </div>
            <div className="file-path-wrapper">
              <input className="file-path validate" type="text" />
            </div>
            {validation?.map((value, index) =>
              value.field === "image" ? (
                <span className="helper-text red-text" key={index}>
                  {value.message}
                </span>
              ) : null
            )}
          </div>
          <div className="input-field">
            <select onChange={(e) => setAuthorId(e.target.value)}>
              <option value="" disabled selected>
                Pilih Penulis
              </option>
              {authors.map((value, index) => (
                <option value={value.id} key={index}>
                  {value.name}
                </option>
              ))}
            </select>
            <label>Penulis</label>
            {validation?.map((value, index) =>
              value.field === "authorId" ? (
                <span className="helper-text red-text" key={index}>
                  {value.message}
                </span>
              ) : null
            )}
          </div>
          <div className="input-field">
            <select
              onChange={(e) => {
                dispatch(getBlogSubCategories(e.target.value));
                setBlogCategoryId(e.target.value);
              }}
            >
              <option value="" disabled selected>
                Pilih Kategori
              </option>
              {blog_categories.map((value, index) => (
                <option value={value.id} key={index}>
                  {value.name}
                </option>
              ))}
            </select>
            <label>Kategori</label>
            {validation?.map((value, index) =>
              value.field === "blogCategoryId" ? (
                <span className="helper-text red-text" key={index}>
                  {value.message}
                </span>
              ) : null
            )}
          </div>
          <div className="input-field">
            <select
              onChange={(e) => {
                setBlogSubCategoryId(e.target.value);
              }}
            >
              <option value="" disabled selected>
                Pilih Sub Kategori
              </option>
              {blog_sub_categories.map((value, index) => (
                <option value={value.id} key={index}>
                  {value.name}
                </option>
              ))}
            </select>
            <label>Sub Kategori</label>
            {validation?.map((value, index) =>
              value.field === "blogSubCategoryId" ? (
                <span className="helper-text red-text" key={index}>
                  {value.message}
                </span>
              ) : null
            )}
          </div>
          <div className="input-field">
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <label>Judul</label>
            {validation?.map((value, index) =>
              value.field === "title" ? (
                <span className="helper-text red-text" key={index}>
                  {value.message}
                </span>
              ) : null
            )}
          </div>
          <div className="input-field">
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
            ></textarea>
            <label className="active">Konten</label>
            {validation?.map((value, index) =>
              value.field === "content" ? (
                <span className="helper-text red-text" key={index}>
                  {value.message}
                </span>
              ) : null
            )}
          </div>
          <div style={styles.containerBtn}>
            <Link to="/blog" className="btn grey">
              <i className="material-icons">arrow_back</i>
            </Link>
            <button className="btn">
              <i className="material-icons">save</i>
            </button>
          </div>
        </form>
      </div>
      <Gap height={70} />
    </>
  );
};

export default BlogCreate;

const styles = {
  card: { padding: 10 },
  image: { width: 70, height: 70 },
  containerBtn: { display: "flex", justifyContent: "space-between" },
};

const stateBlogSubCategories = {
  blog_sub_categories: [],
};

const blogSubCategories = (state = stateBlogSubCategories, action) => {
  if (action.type === "GET_BLOG_SUB_CATEGORIES") {
    return {
      ...state,
      blog_sub_categories: action.payload,
    };
  }

  return state;
};

export { blogSubCategories };

const stateLeaderboard = {
  leaderboard: [],
};

const stateDashboard = {
  dashboard: {},
};

const leaderboard = (state = stateLeaderboard, action) => {
  if (action.type === "GET_LEADERBOARD") {
    return {
      ...state,
      leaderboard: action.payload,
    };
  }

  return state;
};

const dashboard = (state = stateDashboard, action) => {
  if (action.type === "GET_DASHBOARD") {
    return {
      ...state,
      dashboard: action.payload,
    };
  }

  return state;
};

export { leaderboard, dashboard };

import Api from "../../../api/Api";

const getBlogCategories = () => (dispatch) => {
  Api.get("/blog-categories")
    .then((res) => {
      dispatch({
        type: "GET_BLOG_CATEGORIES",
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log(err.response.data);
    });
};

export { getBlogCategories };

import Api from "../../../api/Api";

const getToken = () => {
  return localStorage.getItem("kejetkeun-token");
};

const getLeaderboard = (segmentId, date) => {
  return new Promise((resolve, reject) => {
    Api.get(`/segment-efforts/leaderboard?segmentId=${segmentId}&date=${date}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getDashboard =
  (segmentId = "") =>
  (dispatch) => {
    Api.get(`/segment-efforts/dashboard/segment/${segmentId}`, {
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => {
        dispatch({
          type: "GET_DASHBOARD",
          payload: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

const sync = (code, segmentId, userIds) => {
  return new Promise((resolve, reject) => {
    Api.get(
      `/segment-efforts/sync?code=${code}&segmentId=${segmentId}&userIds=${userIds}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export { getLeaderboard, getDashboard, sync };

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Gap from "../../components/atoms/Gap";
import { getBlogs } from "../../redux/action/blog";
import { getBlogCategories } from "../../redux/action/blogCategory";
import { getBlogSubCategories } from "../../redux/action/blogSubCategory";

const Feed = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBlogCategories());
    dispatch(getBlogs());
  }, [dispatch]);

  const { blog_categories } = useSelector((state) => state.blogCategories);
  const { blog_sub_categories } = useSelector(
    (state) => state.blogSubCategories
  );
  const { blogs } = useSelector((state) => state.blogs);

  const [blogCategoryId, setBlogCategoryId] = useState();
  const [blogSubCategoryId, setBlogSubCategoryId] = useState();

  return (
    <div className="white">
      <div className="container">
        <Gap height={10} />
        <div>
          {blog_categories.map((value, index) => (
            <button
              className={`btn-small ${
                blogCategoryId === value.id ? null : "grey"
              }`}
              style={styles.btn}
              key={index}
              onClick={() => {
                setBlogCategoryId(value.id);
                setBlogSubCategoryId();
                dispatch(getBlogSubCategories(value.id));
                dispatch(getBlogs(value.id));
              }}
            >
              {value.name}
            </button>
          ))}
        </div>
        <Gap height={5} />
        <div>
          {blog_sub_categories.map((value, index) => (
            <button
              className={`btn-small ${
                blogSubCategoryId === value.id ? null : "grey"
              }`}
              style={styles.btn}
              key={index}
              onClick={() => {
                setBlogSubCategoryId(value.id);
                dispatch(getBlogs(blogCategoryId, value.id));
              }}
            >
              {value.name}
            </button>
          ))}
        </div>
        <Gap height={10} />
        <div className="row">
          {blogs.map((value, index) => (
            <div className="col s6" style={{ padding: 2 }}>
              <Link to={`/feed/${value.id}`}>
                <div className="card center" style={styles.card} key={index}>
                  <img
                    src={`//${value.image}`}
                    className="responsive-img"
                    style={styles.image}
                  />
                  <div className="grey-text text-darken-3" style={styles.title}>
                    {value.title}
                  </div>
                  <div
                    className="grey-text text-darken-3"
                    style={styles.content}
                  >
                    {value.content}
                  </div>
                  <Gap height={5} />
                  <div className="grey-text" style={styles.date}>
                    {value.createdAt}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Gap height={50} />
    </div>
  );
};

export default Feed;

const styles = {
  btn: { marginRight: 2 },
  card: { padding: 10 },
  image: { maxHeight: "200px", height: "70%", width: "auto" },
  title: { fontSize: 17 },
  content: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  date: { fontSize: 13 },
};
